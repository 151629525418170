function nextSlide(currentIndex, track, percent) {
  if (currentIndex >= track.children.length - 1) return;
  track.style.transition = "transform .5s ease-in-out";
  currentIndex++;
  track.style.transform = "translateX(-" + (currentIndex * percent) + "%)";
  return currentIndex;
}

function createCarousel(_carousel, _interval) {
  let carousel = document.querySelector(_carousel);
  let track = carousel.querySelector(".carousel-track");
  let nextButton = carousel.querySelector(".carousel-next");
  let prevButton = carousel.querySelector(".carousel-prev");

  let firstClone = track.firstElementChild.cloneNode(true);
  firstClone.classList.add("firstClone");
  let lastClone = track.lastElementChild.cloneNode(true);
  lastClone.classList.add("lastClone");
  track.insertBefore(lastClone, track.children[0]);
  track.appendChild(firstClone);
  let items = track.querySelectorAll(".carousel-item");

  let totalItems = items.length;
  let percent = (100 / totalItems);
  let currentIndex = 1;

  items.forEach((item, index) => {
    items[index].style.width = percent + "%";
  });

  track.style.width = totalItems * 100 + "%";

  track.style.transform = "translateX(-" + (currentIndex * percent) + "%)";

  let autoRotate = setInterval(() => {
    currentIndex = nextSlide(currentIndex, track, percent);
  }, _interval * 1000);

  nextButton.addEventListener("click", () => {
    clearInterval(autoRotate);
    currentIndex = nextSlide(currentIndex, track, percent);
  });

  prevButton.addEventListener("click", () => {
    clearInterval(autoRotate);
    if (currentIndex <= 0) return;
    track.style.transition = "transform .5s ease-in-out";
    currentIndex--;
    track.style.transform = "translateX(-" + (currentIndex * percent) + "%)";
  });

  track.addEventListener("transitionend", () => {
    if (track.children[currentIndex].classList.contains("lastClone")) {
      track.style.transition = "none";
      currentIndex = track.children.length - 2;
      setTimeout(() => {
        track.style.transform = "translateX(-" + (currentIndex * percent) + "%)";
      });
    }
    if (track.children[currentIndex].classList.contains("firstClone")) {
      track.style.transition = "none";
      currentIndex = track.children.length - currentIndex;
      setTimeout(() => {
        track.style.transform = "translateX(-" + (currentIndex * percent) + "%)";
      });
    }
  });
}

createCarousel(".carousel-main", 5);
createCarousel(".carousel-secondary", 5);